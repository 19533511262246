import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import StackTrace from 'stacktrace-js';
import { postErrorLog } from 'services';

export interface CustomError extends Error {
  __proto__: Record<string, any>;
  [key: string]: any;
}

const getErrorStack = (errorObject: CustomError): Promise<any> => {
  if (!errorObject || !errorObject.stack) return Promise.resolve(null);
  return StackTrace
    .fromError(errorObject)
    .then((stackframes) => stackframes.map((stackframe) => stackframe.toString()).join('\n'));
};

const stringifyError = (err: CustomError): string => {
  const plainObject: Record<string, any> = {};
  let propertyNames = Object.getOwnPropertyNames(err);

  if (!propertyNames || propertyNames.length === 0) {
    propertyNames = Object.getOwnPropertyNames(err.__proto__);
  }

  plainObject.type = err.name;

  propertyNames.forEach((key: string) => {
    if (key === 'stack') return;
    plainObject[key] = err[key];
  });

  return JSON.stringify(plainObject, null, '\t');
};

const logErrorToServer = (
  errorMessage: string,
  errorObject: CustomError | null,
  onSuccess?: (value: AjaxResponse<unknown>) => void,
  onError?: (error: any) => void
) => {
  let message = `${errorMessage} \nUser Agent: ${navigator.userAgent}`;

  if (errorObject) {
    message += '\nError object: \n';
    message += stringifyError(errorObject);
  }

  if (errorObject) {
    getErrorStack(errorObject).then((stacktrace) => postErrorLog(message, stacktrace)
      .subscribe({
        next: onSuccess,
        error: onError,
      }));
  } else {
    postErrorLog(message, '')
      .subscribe({
        next: onSuccess,
        error: onError,
      });
  }
};

const logErrorToConsole = (message: string, file: string, line: string, column: string, errorObject: CustomError): void => {
  getErrorStack(errorObject).then((stack) => {
    const errorTxt = [
      '\n\nOops, something went wrong.',
      '\nMessage:\n',
      message,
      '\nError object:\n',
      errorObject,
      '\nFile:\n',
      file,
      '\nLine:\n',
      line,
      '\nColumn:\n',
      column,
      '\nError stack:\n',
      stack,
    ];

    console.log(errorTxt);
  });
};

// NOTE: PromiseRejectionEvent comes from using fetch that we use for image upload in api.ts
// NOTE: Needs to be more specific as 'Failed to fetch' can be thrown on exceptions and slow requests
const isFetchError = (error: any) => error.type === 'unhandledrejection'
  && error.reason.message === 'Failed to fetch';

// NOTE: AjaxError comes from wrapping ajax request in rxjs/ajax
const isAjaxError = (error: any) => error instanceof AjaxError;
const isAjaxFetchError = (error: any) => isAjaxError(error) && error.status === 0;
const isInternetConnectionError = (error: any) => isAjaxFetchError || isFetchError(error);

export {
  logErrorToConsole,
  logErrorToServer,
  isInternetConnectionError,
  isAjaxError
};
