import React, { useCallback } from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import _ from 'lodash';
import styled from 'styled-components';

import { useTheme } from 'hooks';
import { SvgWrapper } from 'assets';
import { CameraMode, VerificationError } from '../../store/root';
import { ReactComponent as IconRetake } from 'assets/svgs/retake.svg';
import { Button, ButtonContent, Portal } from 'components/partials';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ReactComponent as IconWrongPose } from 'assets/svgs/ai-feedback/wrong-pose.svg';
import { ReactComponent as IconBlurryImg } from 'assets/svgs/ai-feedback/blurry-img.svg';
import { ReactComponent as IconVinMismatch } from 'assets/svgs/ai-feedback/vin-mismatch.svg';
import { ReactComponent as IconVinNotFound } from 'assets/svgs/ai-feedback/vin-not-found.svg';
import { ReactComponent as IconVehicleTooFar } from 'assets/svgs/ai-feedback/vehicle-too-far.svg';
import { ReactComponent as IconError } from 'assets/svgs/ai-feedback/icn-exclamation-ball-small.svg';
import { ReactComponent as IconVehicleNotFound } from 'assets/svgs/ai-feedback/vehicle-not-found.svg';
import { genericErrorNumberDenotation, genericErrors, imageVerificationErrors } from 'utils/constants.util';
import { ReactComponent as IconWindshieldNotFound } from 'assets/svgs/ai-feedback/windshield-not-found.svg';
import { ReactComponent as IconLicensePlateMismatch } from 'assets/svgs/ai-feedback/license-plate-mismatch.svg';
import { ReactComponent as IconLicensePlateNotFound } from 'assets/svgs/ai-feedback/license-plate-not-found.svg';
import { ReactComponent as IconVehicleTooCloseToImageEdge } from 'assets/svgs/ai-feedback/vehicle-too-close-to-img-edge.svg';
import { ReactComponent as IconWindshieldTooCloseToImageEdge } from 'assets/svgs/ai-feedback/windshield-too-close-to-img-edge.svg';
import { ReactComponent as IconDirty } from 'assets/svgs/ai-feedback/dirty.svg';
import { ReactComponent as IconSnowy } from 'assets/svgs/ai-feedback/snowy.svg';
import { ReactComponent as IconRainy } from 'assets/svgs/ai-feedback/rainy.svg';
import { ReactComponent as IconGlared } from 'assets/svgs/ai-feedback/glared.svg';
import { ReactComponent as IconImageOverexposed } from 'assets/svgs/ai-feedback/image-overexposed.svg';
import { EnabledVerificationErrors, IMAGE_VERIFICATION_ERROR_TYPE } from 'redux/workflows/workflows';

const { vehicle_scan, server_error, timeout_error } = TRANSLATIONS_VALUES_KEYS;

const ErrorModalWrapper = styled.div`
  background: linear-gradient(180deg, rgba(235, 241, 248, 0.87) 0%, #ffffff 100%);
  backdrop-filter: blur(24px);
  position: absolute;
  z-index: 900000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  min-height: var(--view-height);
  text-align: center;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media screen and (min-aspect-ratio: 13/9) {
    margin-top: var(--page-margin-top);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-aspect-ratio: 13/9) {
    transform: rotate(90deg);
    transform-origin: left top;
    min-width: var(--view-height);
    max-width: var(--view-height);
    left: 100vw;
    min-height: 100vw;
    max-height: 100vw;
  }

  & p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1875rem;
  }

  & .icon > * {
    justify-content: center;
  }
`;

const Heading = styled.h1`
  font-size: 1.75rem;
  padding-top: 2rem;
`;

const VerificationErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 11rem;
  overflow-y: scroll;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  height: 11rem;
`;

const VerificationErrorWrapper = styled.div`
  display: flex;
  margin: 0 4rem;

  & > span {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    padding-left: 1rem;
  }
`;

const ButtonWrapper = styled.div<{ buttonMinWidth: string }>`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 1.875rem;

  & > button {
    min-width: ${({ buttonMinWidth }) => buttonMinWidth};
    max-width: 15.625rem !important;
    margin: 0 0.625rem;
  }
`;

const Row = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type Vehicle = {
  registrationNo: string;
  vin: string;
};

export type ErrorModalProps = {
  onEnterManually?: React.MouseEventHandler<HTMLButtonElement>;
  onRetake: React.MouseEventHandler<HTMLButtonElement>;
  errors: VerificationError[];
  vehicle?: Vehicle;
  isVinError?: boolean;
  shownModal: boolean;
  showContinueButton?: boolean;
  isBlocker?: boolean;
  onContinue?: React.MouseEventHandler<HTMLButtonElement>;
  enabledVerificationErrors?: (EnabledVerificationErrors | null)[] | null,
  cameraMode: CameraMode
};

const VerificationErrorIcons: { [key: number]: React.ReactElement } = {
  [imageVerificationErrors.noVin]: <IconVinNotFound />,
  [imageVerificationErrors.wrongPose]: <IconWrongPose />,
  [imageVerificationErrors.lowQuality]: <IconBlurryImg />,
  [imageVerificationErrors.vinMismatch]: <IconVinMismatch />,
  [imageVerificationErrors.colorMismatch]: <IconVinMismatch />,
  [imageVerificationErrors.vehicleTooFar]: <IconVehicleTooFar />,
  [imageVerificationErrors.noRegistrationNo]: <IconLicensePlateNotFound />,
  [imageVerificationErrors.vehicleNotInTheCenter]: <IconVehicleNotFound />,
  [imageVerificationErrors.windshieldNotInFrame]: <IconWindshieldNotFound />,
  [imageVerificationErrors.vehicleNotInFrame]: <IconVehicleTooCloseToImageEdge />,
  [imageVerificationErrors.registrationNoMismatch]: <IconLicensePlateMismatch />,
  [imageVerificationErrors.dirty]: <IconDirty />,
  [imageVerificationErrors.snowy]: <IconSnowy />,
  [imageVerificationErrors.glared]: <IconGlared />,
  [imageVerificationErrors.rainy]: <IconRainy />,
  [imageVerificationErrors.overexposed]: <IconImageOverexposed />,
  [genericErrorNumberDenotation.genericError]: <IconError />,
  [imageVerificationErrors.windshieldNotInTheCenter]: <IconWindshieldTooCloseToImageEdge />
};

const getErrorText = (
  e: VerificationError, cameraMode: CameraMode, translate: TranslateFunction,
  enabledVerificationErrors: (EnabledVerificationErrors | null)[] | null | undefined, vehicle?: Vehicle
) => {
  const verificationError = enabledVerificationErrors?.find((err) => err?.error === e.error);

  // NOTE:  Overexposed verification error type is not currently controlled by workflow image type settings
  //        We handle it as blocker error
  const isBlocker = verificationError && (verificationError.errorType === IMAGE_VERIFICATION_ERROR_TYPE.BLOCKER ||
    verificationError.error === imageVerificationErrors.overexposed);

  switch (e.error!) {
    case imageVerificationErrors.wrongPose: {
      const { errorDetails } = e;
      const errorDetailsParts = errorDetails.replace(/ /g, '').split(',');
      const pose: {
        [key: string]: string
      } = vehicle_scan.pose;

      let predictedString = errorDetailsParts.find((part) => part.toLowerCase().includes('predicted'));
      let expectedString = errorDetailsParts.find((part) => part.toLowerCase().includes('expected'));

      let predicted: string = '';
      let expected: string = '';

      if (predictedString?.includes(':')) predicted = _.snakeCase(predictedString.split(':')[1]);
      if (expectedString?.includes(':')) expected = _.snakeCase(expectedString.split(':')[1]);

      const wrongPoseError = translate(vehicle_scan.wrongPose_error) as string;
      return _.replace(
        _.replace(wrongPoseError, '${expected}', translate(pose[expected]) as string), '${actual}', translate(pose[predicted]) as string
      );
    }
    case imageVerificationErrors.lowQuality: {
      return translate(vehicle_scan.imageBlurry_error);
    }
    case imageVerificationErrors.vehicleNotInFrame: {
      return translate(vehicle_scan.vehicleNotInFrame_error);
    }
    case imageVerificationErrors.noRegistrationNo: {
      return translate(vehicle_scan.noRegistrationNo_error);
    }
    case imageVerificationErrors.noVin: {
      return translate(vehicle_scan.noVin_error);
    }
    case imageVerificationErrors.vehicleTooFar: {
      return translate(vehicle_scan.vehicleTooFar_error);
    }
    case imageVerificationErrors.vehicleNotInTheCenter: {
      return translate(vehicle_scan.vehicleNotInTheCenter_error);
    }
    case imageVerificationErrors.windshieldNotInTheCenter: {
      return translate(vehicle_scan.windshieldNotInTheCenter_error);
    }
    case imageVerificationErrors.windshieldNotInFrame: {
      return translate(vehicle_scan.windshieldNotInFrame_error);
    }
    case imageVerificationErrors.registrationNoMismatch: {
      const errorText: string = translate(vehicle_scan.registrationNoMismatch_error) as string;

      if (!errorText) return null;

      return _.replace(_.replace(errorText, '${expected}', vehicle!.registrationNo), '${actual}', e.errorDetails);
    }
    case imageVerificationErrors.vinMismatch: {
      const errorText: string = translate(vehicle_scan.vinMismatch_error) as string;

      if (!errorText) return null;

      return _.replace(_.replace(errorText, '${expected}', vehicle!.vin), '${actual}', e.errorDetails);
    }
    case imageVerificationErrors.dirty: {
      return translate(isBlocker ? vehicle_scan.blocker.dirty : vehicle_scan.warning.dirty);
    }
    case imageVerificationErrors.snowy: {
      return translate(isBlocker ? vehicle_scan.blocker.snowy : vehicle_scan.warning.snowy);
    }
    case imageVerificationErrors.rainy: {
      return translate(isBlocker ? vehicle_scan.blocker.rainy : vehicle_scan.warning.rainy);
    }
    case imageVerificationErrors.glared: {
      return translate(isBlocker ? vehicle_scan.blocker.glared : vehicle_scan.warning.glared);
    }
    case imageVerificationErrors.overexposed: {
      return translate(
        cameraMode === CameraMode.InBrowser
          ? vehicle_scan.too_bright_browser_camera_message
          : vehicle_scan.too_bright_native_camera_message
      );
    }
    // generic error
    case genericErrorNumberDenotation.genericError: {
      if (e.errorDetails === genericErrors.serverError) {
        return translate(server_error.body);
      }

      if (e.errorDetails === genericErrors.timeoutError) {
        return translate(timeout_error.body);
      }

      if (e.errorDetails === genericErrors.lowResolution) {
        return translate(vehicle_scan.cameraResolution_error);
      }

      if (e.errorDetails === genericErrors.cameraNotReadable) {
        return translate(vehicle_scan.cameraNotReadable_error);
      }
      break;
    }
    default: {
      break;
    }
  }
};

export function ErrorModal(props: ErrorModalProps) {
  const {
    shownModal, onEnterManually, onRetake, isBlocker, errors, vehicle,
    isVinError, showContinueButton, onContinue, enabledVerificationErrors,
    cameraMode
  } = props;
  const { styles } = useTheme();

  const renderVerificationErrors = useCallback(
    (translate: TranslateFunction) => {
      const _errors = (
        <VerificationErrorsWrapper>
          {errors?.map((val) => (
            <VerificationErrorWrapper key={val.error}>
              <div className="icon">
                <SvgWrapper size="medium">{VerificationErrorIcons[val.error]}</SvgWrapper>
              </div>
              <span className="error-text" data-testid={`error-text-${val.error}`}>
                {getErrorText(val, cameraMode, translate, enabledVerificationErrors, vehicle)}
              </span>
            </VerificationErrorWrapper>
          ))}
        </VerificationErrorsWrapper>
      );

      return _errors;
    },
    [vehicle, errors, enabledVerificationErrors, cameraMode]
  );

  const secondaryButtonProps = {
    bg: styles.colorSecondaryButtonBackground,
    color: styles.colorSecondaryButtonText,
    hoverBorderColor: styles.colorSecondaryButtonBackgroundDark,
    hoverShadowColor: styles.colorSecondaryButtonBackgroundLight,
  };

  function renderSecondaryButton(translate: TranslateFunction) {
    let node = null;

    if (isVinError && !isBlocker && onEnterManually) {
      node = (
        <Button {...secondaryButtonProps} type="button" testId="enterManuallyBtn" onClick={onEnterManually}>
          {translate(vehicle_scan.enter_manually_btn_text)}
        </Button>
      );

      return node;
    }

    if (showContinueButton) {
      node = (
        <Button {...secondaryButtonProps} type="button" testId="continueBtn" onClick={onContinue}>
          {translate(vehicle_scan.continue_btn)}
        </Button>
      );
    }

    return node;
  }
  const overExposedErrorInBrowser = errors && errors.some((e) => e.error === imageVerificationErrors.overexposed)
    && cameraMode === CameraMode.InBrowser;

  if (!shownModal) return null;

  return (
    <Portal target=".smartscan-fullscreen">
      <Translate>
        {({ translate }) => (
          <ErrorModalWrapper className="wrapper page">
            <Heading>{translate(vehicle_scan.ai_feedback_modal_heading)}</Heading>
            <Row>
              {renderVerificationErrors(translate)}
              <ButtonWrapper buttonMinWidth={overExposedErrorInBrowser ? '52%' : '48%'}>
                {renderSecondaryButton(translate)}
                <Button type="button" testId="retakeImgBtn" onClick={onRetake} animate>
                  <ButtonContent>
                    <SvgWrapper size="small" stroke={styles.colorPrimaryButtonText} fill={styles.colorPrimaryButtonText}>
                      <IconRetake />
                    </SvgWrapper>
                    &nbsp;{translate(overExposedErrorInBrowser ? vehicle_scan.too_bright_browser_camera_btn : vehicle_scan.retake_btn_text)}
                  </ButtonContent>
                </Button>
              </ButtonWrapper>
            </Row>
          </ErrorModalWrapper>
        )}
      </Translate>
    </Portal>
  );
}
