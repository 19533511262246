import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { BodyPart, IDamageLocation, IPoint, DamageType, ICustomDamageType } from 'redux/damages/damages';
import { ConfirmCancelModal } from 'components/partials';
import { BodyPartModal, DamageTypeModal } from './modal.component';
import { ImageTypeKeys } from 'redux/workflows/workflows';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

import { usePhotoSeriesStore } from '../../../../store/root.hook';
import { useIsScreenPortrait } from 'hooks';
import { addNewDamage, deleteNewDamage } from 'redux/root.actions';
import { CapturedImageWithZoom, ZoomArea } from '../captured-image-with-zoom.component';
import { getCustomDamageTypes } from '../../../../../../../redux/damages/damages.selectors';
import { calculateDamageLocationMask } from 'redux/damages/damages.reducer';

const { damage_labelling } = TRANSLATIONS_VALUES_KEYS;

const Svg = styled.svg<{ height: number, width: number }>`
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;

// SmartScan base font size is fixed to 16px
const pxPerRem = 16;
const lineWidthRems = 1;
const lineWidthBufferPx = lineWidthRems * pxPerRem;

const Path = styled.path`
  fill: none;
  stroke-width: ${lineWidthRems}rem;
  stroke-linejoin: round;
  stroke-linecap: round;
  opacity: 0.7;
`;

const Circle = styled.circle`
  opacity: 0.7;
`;

const CaptureImageWrapper = styled.div`
  z-index: 2;
  touch-action: none;
`;

type Line = {
  lineId: number;
  coords: IPoint[];
  zoom: boolean;
  lineWidthMultiplier?: number;
};

type DamageLabellingAreaProps = {
  imageWidth: number;
  imageHeight: number;
  imageId: string,
  imageType: number;
  imageSubType: number;
  imageObjectUrl: string;

  exitZoomMode: boolean;

  onConfirmNoDamages: Function | null;
  onDrawStart?: () => void;
  onDamageTypeSelected?: () => void;
  onZoomAreaSelected?: (selected: boolean) => void;
}

type ImageSize = {
  height: number,
  width: number,
}

type ImageSizeOnScreen = {
  dimensions: ImageSize,
  oldDimensions?: ImageSize | undefined
}

export const DamageLabellingArea = ({
  imageId, imageWidth, imageHeight, imageType, imageSubType, imageObjectUrl, exitZoomMode,
  onConfirmNoDamages, onDrawStart, onDamageTypeSelected, onZoomAreaSelected
}: DamageLabellingAreaProps) => {
  /* Refs */
  const imageRef = useRef<HTMLImageElement>(null!);

  /* Functional component states */
  const [isDrawing, setIsDrawing] = useState(false);
  const [imageSizeOnScreen, setImageSizeOnScreen] = useState<ImageSizeOnScreen>({
    dimensions: { height: 0, width: 0 }
  });
  const [lines, setLines] = useState<Line[]>([]);
  const [showBodyPartModal, setShowBodyPartModal] = useState(false);
  const [showDamageTypeModal, setShowDamageTypeModal] = useState(false);
  const [selectedBodyPart, setSelectedBodyPart] = useState<BodyPart | undefined>(undefined);
  const [confirmDeleteLine, setConfirmDeleteLine] = useState<number | null>(null);

  const [zoomArea, setZoomArea] = useState<ZoomArea | null>(null);

  /* Redux */
  const dispatch = useDispatch();
  const { dispatchSetConfirmNoDamages } = usePhotoSeriesStore();

  /* Selectors */
  const selectCustomDamageTypes : ICustomDamageType[] = useSelector(getCustomDamageTypes);

  /* Hooks */
  const isPortrait = useIsScreenPortrait();

  const getMaxCoords = useCallback((coords: IPoint[], buffer: number) => {
    const xCoords = coords.map((coord) => coord.x);
    const yCoords = coords.map((coord) => coord.y);

    const maxX = Math.max(...xCoords) + buffer;
    const maxY = Math.max(...yCoords) + buffer;

    return {
      x: maxX > imageSizeOnScreen.dimensions.width ? imageSizeOnScreen.dimensions.width : maxX,
      y: maxY > imageSizeOnScreen.dimensions.height ? imageSizeOnScreen.dimensions.height : maxY,
    };
  }, [imageSizeOnScreen]);

  const getMinCoords = useCallback((coords: IPoint[], buffer: number): IPoint => {
    const xCoords = coords.map((coord) => coord.x);
    const yCoords = coords.map((coord) => coord.y);

    const calculatedMinX = Math.min(...xCoords) - buffer;
    const calculatedMinY = Math.min(...yCoords) - buffer;

    return {
      x: calculatedMinX < 0 ? 0 : calculatedMinX,
      y: calculatedMinY < 0 ? 0 : calculatedMinY,
    };
  }, []);

  const getLastDamageBoundingBox = (buffer: number): IDamageLocation => getDamageBoundingBoxByIndex(lines.length - 1, buffer);
  const getDamageBoundingBoxByIndex = (index: number, buffer: number): IDamageLocation => {
    const lastLine = lines[index];
    return getDamageBoundingBoxFromCoords(lastLine.coords, buffer);
  };
  const getDamageBoundingBoxFromCoords = useCallback((coords: IPoint[], buffer: number): IDamageLocation => ({
    mask: [
      getMinCoords(coords, buffer),
      getMaxCoords(coords, buffer)
    ]
  }), [getMinCoords, getMaxCoords]);

  const resetDimensions = () => setImageSizeOnScreen({
    dimensions: { height: 0, width: 0 },
    oldDimensions: { height: imageSizeOnScreen.dimensions.height, width: imageSizeOnScreen.dimensions.width }
  });

  /* Effects */
  useEffect(() => {
    // window.addEventListener('touchcancel', handleTouchEnd);
    window.addEventListener('resize', resetDimensions);

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', resetDimensions);
    } else {
      window.addEventListener('orientationchange', resetDimensions);
    }

    return () => {
      // window.removeEventListener('touchcancel', handleTouchEnd);
      window.removeEventListener('resize', resetDimensions);

      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', resetDimensions);
      } else {
        window.removeEventListener('orientationchange', resetDimensions);
      }
    };
  });

  useLayoutEffect(() => {
    if (imageSizeOnScreen.oldDimensions !== undefined) {
      const height = imageRef.current.height;
      const width = imageRef.current.width;

      const oldHeight = imageSizeOnScreen.oldDimensions.height;
      const oldWidth = imageSizeOnScreen.oldDimensions.width;

      if (lines.length > 0) {
        const heightRatio = height / oldHeight;
        const widthRatio = width / oldWidth;

        setLines(lines.map((line) => ({
          lineId: line.lineId,
          coords: line.coords.map((point) => ({
            x: point.x * heightRatio,
            y: point.y * widthRatio
          })),
          zoom: line.zoom,
          lineWidthMultiplier: line.lineWidthMultiplier
        })));
      }

      setImageSizeOnScreen({
        dimensions: { height, width },
        oldDimensions: undefined
      });
    }
  }, [imageSizeOnScreen, imageRef, lines]);

  useEffect(() => {
    if (!exitZoomMode) return;
    if (onZoomAreaSelected) {
      onZoomAreaSelected(false);
    }

    if (zoomArea && zoomArea.cropped) {
      const newLines = lines
        .map((line) => {
          if (line.zoom) {
            const croppedImageWidthRatio = (zoomArea.cropped?.width! / imageSizeOnScreen.dimensions.width);
            const croppedImageHeightRatio = (zoomArea.cropped?.height! / imageSizeOnScreen.dimensions.height);

            const downscaleWidthRatio = imageSizeOnScreen.dimensions.width / imageWidth;
            const downscaleHeightRatio = imageSizeOnScreen.dimensions.height / imageHeight;

            const mask = line.coords.map((point) => (
              {
                x: (zoomArea.cropped?.x! + (point.x * croppedImageWidthRatio)) * downscaleWidthRatio,
                y: (zoomArea.cropped?.y! + (point.y * croppedImageHeightRatio)) * downscaleHeightRatio
              }
            ));

            const lineWidthMultiplier = zoomArea.cropped!.width / imageWidth;

            const damage = {
              bodyPart: BodyPart.Windshield,
              damageType: DamageType.Chip,
              damageLocation: getDamageBoundingBoxFromCoords(mask, lineWidthBufferPx * lineWidthMultiplier),
              undefined,
              imageType
            };

            const imageData = { width: imageWidth, height: imageHeight };
            const screenFeedData = { width: imageSizeOnScreen.dimensions.width, height: imageSizeOnScreen.dimensions.height };

            dispatch(addNewDamage(imageId, damage, imageData, screenFeedData));

            return {
              lineId: line.lineId,
              coords: mask,
              zoom: false,
              lineWidthMultiplier
            };
          }

          return line;
        });

      setLines(newLines);
      setZoomArea(null);
    }
  }, [
    exitZoomMode, lines, onZoomAreaSelected, imageSizeOnScreen, imageHeight, imageWidth, zoomArea,
    dispatch, imageId, imageType, getDamageBoundingBoxFromCoords
  ]);

  /* Helpers */
  const getCoordinatesForTouch = (touch: React.Touch): IPoint => {
    if (isPortrait) {
      return {
        x: touch.clientY - imageRef.current.offsetLeft,
        y: imageRef.current.getBoundingClientRect().width - touch.clientX
      };
    }

    return {
      x: touch.clientX - imageRef.current.offsetLeft,
      y: touch.clientY
    };
  };

  /* Event handlers */
  const handleBodyPartSelected = (selectedBodyPart: BodyPart) => {
    setSelectedBodyPart(selectedBodyPart);
    setShowBodyPartModal(false);

    if (zoomArea !== null) {
      handleDamageTypeSelected(DamageType.Chip, undefined);
    } else {
      setShowDamageTypeModal(true);
    }
  };

  const handleDamageTypeSelected = (selectedDamageType: DamageType, customDamageTypeId?: string) => {
    if (!selectedBodyPart) {
      setShowBodyPartModal(true);
      return;
    }

    const imageData = { width: imageWidth, height: imageHeight };
    const screenFeedData = { width: imageSizeOnScreen.dimensions.width, height: imageSizeOnScreen.dimensions.height };

    if (selectedDamageType === DamageType.Chip && zoomArea !== null) {
      // no-op, we convert back after user has finished zooming
    } else if (selectedDamageType === DamageType.Chip && zoomArea == null) {
      const damageLocationMask = calculateDamageLocationMask(getLastDamageBoundingBox(lineWidthBufferPx), imageData, screenFeedData);
      setZoomArea({
        requested: {
          x: damageLocationMask[0].x,
          y: damageLocationMask[0].y,
          width: damageLocationMask[1].x - damageLocationMask[0].x,
          height: damageLocationMask[1].y - damageLocationMask[0].y
        }
      });

      // remove line from lines as it is only used for zooming into chip damage
      const deletedLine = lines[lines.length - 1];
      setLines(lines.filter((line) => line.lineId !== deletedLine.lineId));

      setShowDamageTypeModal(false);

      if (onZoomAreaSelected) onZoomAreaSelected(true);
    } else {
      const damage = {
        bodyPart: selectedBodyPart,
        damageType: selectedDamageType,
        damageLocation: getLastDamageBoundingBox(lineWidthBufferPx),
        customDamageTypeId,
        imageType
      };

      setShowDamageTypeModal(false);
      setSelectedBodyPart(undefined);
      dispatch(addNewDamage(imageId, damage, imageData, screenFeedData));

      if (onDamageTypeSelected) onDamageTypeSelected();
    }
  };

  const handlePathClick = (lineId: number) => setConfirmDeleteLine(lineId);

  const handleConfirmDelete = () => {
    const removedLine = lines.find((line) => line.lineId === confirmDeleteLine);
    if (!removedLine) {
      setConfirmDeleteLine(null);
      return;
    }

    if (!removedLine.zoom) {
      const removedLineIndex = lines.indexOf(removedLine);

      // fixme: there might be very small chance that damage bounding box is overlapping with exact same coordinates
      //        and we need to also identify bodypart. That means Line needs also hold bodyPartId
      const imageData = { width: imageWidth, height: imageHeight };
      const screenFeedData = { width: imageSizeOnScreen.dimensions.width, height: imageSizeOnScreen.dimensions.height };
      const buffer = removedLine.lineWidthMultiplier !== undefined
        ? lineWidthBufferPx * removedLine.lineWidthMultiplier
        : lineWidthBufferPx;

      dispatch(deleteNewDamage(imageId, getDamageBoundingBoxByIndex(removedLineIndex, buffer), imageData, screenFeedData));
    }

    setLines([
      ...lines.filter((line: Line) => (line.lineId !== confirmDeleteLine)),
    ]);

    setConfirmDeleteLine(null);
  };

  /* Touch event handlers */
  const handleTouchStart = (touchEvent: React.TouchEvent<SVGSVGElement>) => {
    if (touchEvent.changedTouches.length > 1) return;
    if (confirmDeleteLine) return;

    const touch = touchEvent.changedTouches[0];
    const point = getCoordinatesForTouch(touch);

    setLines([
      ...lines,
      {
        lineId: lines.length + 1,
        coords: [point],
        zoom: zoomArea !== null
      }
    ]);
    setIsDrawing(true);
    if (onDrawStart) onDrawStart();
  };

  const handleTouchMove = (touchEvent: React.TouchEvent<SVGSVGElement>) => {
    if (!isDrawing) return;
    if (touchEvent.changedTouches.length > 1) return;
    if (zoomArea !== null) return;

    const touch = touchEvent.changedTouches[0];
    const point = getCoordinatesForTouch(touch);
    const lastLineIndex = lines.length - 1;

    const updatedLines = [...lines];
    updatedLines[lastLineIndex] = {
      ...lines[lastLineIndex],
      coords: [
        ...lines[lastLineIndex].coords,
        point,
      ],
    };

    setLines(updatedLines);
  };

  const handleTouchEnd = () => {
    if (!isDrawing) return;

    if (zoomArea === null) {
      const copyOfLines = [...lines];
      const firstLine = copyOfLines.pop();

      if (firstLine && firstLine.coords.length > 1) {
        if (imageType === ImageTypeKeys.Windshield) {
          handleBodyPartSelected(BodyPart.Windshield);
        } else {
          setShowBodyPartModal(true);
        }
      } else {
        setLines([
          ...copyOfLines,
        ]);
      }
    // If we are in zoom we select damage type automatically
    } else if (onDamageTypeSelected) onDamageTypeSelected();

    setIsDrawing(false);
  };

  /* Objects */
  const confirmCancelModal = {
    show: onConfirmNoDamages || confirmDeleteLine,
    questionTranslationKey: onConfirmNoDamages
      ? damage_labelling.confirm_no_damages_question
      : damage_labelling.confirm_damage_removal_question,
    onConfirm: {
      buttonTextTranslationKey: damage_labelling.confirm_damage_removal_yes,
      click: onConfirmNoDamages || handleConfirmDelete,
    },
    onCancel: {
      buttonTextTranslationKey: damage_labelling.confirm_damage_removal_no,
      click: onConfirmNoDamages
        ? () => dispatchSetConfirmNoDamages(null)
        : () => setConfirmDeleteLine(null),
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <CaptureImageWrapper>
        <CapturedImageWithZoom
          imageRef={imageRef}
          src={imageObjectUrl}
          alt={imageId}
          onLoad={(event: any) => {
            const { height, width } = event.target;
            setImageSizeOnScreen({
              dimensions: { height, width }
            });
          }}
          zoomArea={zoomArea}
          setZoomArea={setZoomArea}
        >
          <Drawing
            handleLineClick={handlePathClick}
            lines={zoomArea !== null ? lines.filter((line: Line) => line.zoom) : lines.filter((line: Line) => !line.zoom)}
            height={imageSizeOnScreen.dimensions.height}
            width={imageSizeOnScreen.dimensions.width}
            onTouchStart={handleTouchStart}
            onTouchCancel={handleTouchEnd}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
          />
        </CapturedImageWithZoom>
      </CaptureImageWrapper>
      { showBodyPartModal && (<BodyPartModal imageSubType={imageSubType} onBodyPartSelected={handleBodyPartSelected} />) }
      {
        showDamageTypeModal && (
          <DamageTypeModal
            customDamageTypes={selectCustomDamageTypes}
            onDamageTypeSelected={handleDamageTypeSelected}
            bodyPart={selectedBodyPart}
          />
        )
      }
      {
        confirmCancelModal.show && (
          <ConfirmCancelModal
            confirmTextTranslationKey={confirmCancelModal.questionTranslationKey}
            onConfirm={confirmCancelModal.onConfirm}
            onCancel={confirmCancelModal.onCancel}
          />
        )
      }
    </div>
  );
};

type DrawingPropTypes = {
  lines: Line[];
  handleLineClick: Function;
  height: number;
  width: number;
  onTouchStart: React.TouchEventHandler<SVGSVGElement>;
  onTouchMove: React.TouchEventHandler<SVGSVGElement>;
  onTouchEnd: React.TouchEventHandler<SVGSVGElement>;
  onTouchCancel: React.TouchEventHandler<SVGSVGElement>;
};

const Drawing: React.FC<DrawingPropTypes> = ({
  lines,
  handleLineClick,
  height,
  width,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  onTouchCancel,
}) => (
  <Svg
    data-testid="drawing-area"
    className="drawing"
    height={height}
    width={width}
    onTouchStart={onTouchStart}
    onTouchMove={onTouchMove}
    onTouchEnd={onTouchEnd}
    onTouchCancel={onTouchCancel}
  >
    {lines
      .map((line) =>
        (<DrawingLine key={`${line.lineId}${line.coords.map((p) => (`${p.x}${p.y}`)).join('')}`} line={line} onClick={handleLineClick} />))}
  </Svg>
);

type DrawingLinePropTypes = {
  line: Line;
  onClick: Function;
};

const getColor = (lineId: number) => {
  const colorIndex = lineId < 10
    ? lineId
    : Number(String(lineId).slice(-1));

  return colors[colorIndex];
};

const DrawingLine: React.FC<DrawingLinePropTypes> = ({ line, onClick }) => {
  const handleOnTouchStart = (event: any) => {
    event.stopPropagation();
    onClick(line.lineId);
  };

  const handleOnTouchEnd = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (line.coords.length > 1) {
    const pathData = `M ${line.coords.map((p) => (`${p.x} ${p.y}`)).join(' L ')}`;
    return (<Path
      className="path"
      d={pathData}
      stroke={`rgba(${getColor(line.lineId)}, 0.7)`}
      onTouchStart={handleOnTouchStart}
      onTouchEnd={handleOnTouchEnd}
    />);
  }

  if (!line.zoom && !line.lineWidthMultiplier) return null;

  const radius = line.lineWidthMultiplier !== undefined
    ? lineWidthBufferPx * line.lineWidthMultiplier
    : lineWidthBufferPx;

  return (
    <Circle
      className="path"
      cx={line.coords[0].x}
      cy={line.coords[0].y}
      r={radius}
      fill={`rgba(${getColor(line.lineId)}, 0.7)`}
      onTouchStart={handleOnTouchStart}
      onTouchEnd={handleOnTouchEnd}
    />
  );
};

const colors = [
  '255,0,0',
  '0,255,0',
  '0,0,255',
  '255,255,0',
  '255,0,255',
  '210,105,30',
  '0,255,255',
  '138,43,226',
  '255,140,0',
  '143,188,143',
];
