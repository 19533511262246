import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useForceUpdate } from 'hooks';

type PortalProps = {
  target: '.smartscan-fullscreen' | '.landscape-lock' | '.portrait-lock' | undefined;
  elementNode?: string;
  children: React.ReactNode;
  direction: 'start' | 'end';
};

export function Portal(props: PortalProps) {
  const portalNode = useRef<HTMLElement | null>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    // Determine the appropriate target node
    let targetNode = (props.target && document.querySelector(props.target)) ||
                     document.querySelector('.portrait-lock') ||
                     document.querySelector('.landscape-lock') ||
                     document.querySelector('.smartscan-fullscreen');

    if (targetNode) {
      portalNode.current = document.createElement('div');

      if (props.direction === 'start') {
        targetNode.prepend(portalNode.current); // Insert at the start
      } else {
        targetNode.appendChild(portalNode.current); // Insert at the end
      }

      forceUpdate();
    }

    return () => {
      if (targetNode && portalNode.current) {
        targetNode.removeChild(portalNode.current);
      }
    };
  }, [forceUpdate, props.direction, props.target]); // Add props.direction as a dependency

  return portalNode.current && createPortal(props.children, portalNode.current);
}

Portal.defaultProps = {
  target: undefined,
  elementNode: 'smartscanportal',
  direction: 'end'
};
